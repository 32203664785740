.Dialog {
  &-Container {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    pointer-events: auto;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &-AlertDialog {
    background-color: rgba(255, 200, 200, 0.9);
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 90%;
    padding: 12px;

    &-ButtonContainer {
      align-items: center;
      display: flex;
      justify-content: center;

      button {
        margin: 12px 8px 0;
      }
    }
  }

  &-ConfirmDialog {
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 90%;
    padding: 12px;

    &-ButtonContainer {
      align-items: center;
      display: flex;
      justify-content: center;

      button {
        margin: 12px 8px 0;
      }
    }
  }

  &-LoadingDialog {
    animation: OpacityAnim 3s linear infinite;
    background-color: rgba(200, 200, 200, 0.9);
    padding: 12px;

    @keyframes OpacityAnim {
      from {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      to {
        opacity: 1;
      }
    }
  }

  &-PromptDialog {
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 90%;
    padding: 12px;

    &-ButtonContainer {
      align-items: center;
      display: flex;
      justify-content: center;

      button {
        margin: 12px 8px 0;
      }
    }
  }
}
