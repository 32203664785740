.SplashContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.SplashContents {
  align-items: center;
  background-color: rgba(255, 200, 0, 0.1);
  border: rgba(255, 200, 0, 0.8) 4px solid;
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: 24px;
  padding: 12px;
  width: 400px;
}
