.UserPerson {
  padding: 16px;
}

.UserPersonHello {
  font-size: 24px;
  font-weight: 700;
}

.UserPersonInstructions {
  font-size: 14px;
}

.UserPersonButtonContainer {
  margin-top: 16px;
}
