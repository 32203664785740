$border: black 1px solid;

.FamilyTreeConnection {
  position: absolute;
}

.FamilyTreeConnectionVertical {
  border-left: $border;
}

.FamilyTreeConnectionHorizontal {
  border-top: $border;
}
