@import "../../mixins";

.PersonPage {
  @include fullWidth;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-TopBar {
    background-color: rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    padding: 8px 16px;
    width: 100%;
  }

  &-Name {
    box-sizing: border-box;
    font-size: 32px;
    font-weight: 600;
    padding: 16px 16px 0;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    &-Name {
      text-align: center;
    }
  }

  &-ThumbnailFamilyTreeContainer {
    cursor: pointer;
    transform: scale(0.8);
  }

  &-PhotoGallery {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 12px;
    padding: 12px;

    &-NoPhotos {
      font-size: 12px;
      font-style: italic;
      margin-bottom: 12px;
    }

    &-AddPhoto {
      font-size: 14px;
    }
  }

  &-ButtonContainer {
    margin: 12px;
    text-align: center;
  }
}
