@import "../../mixins";

.ListPeople {
  @include fullWidth;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
}

.ListPeopleSection {
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 8px;
  padding: 8px;
}

.ListPeopleEntry {
  align-items: center;
  background-color: white;
  border: solid rgba(0, 0, 0, 0.1) 1px;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 4px;
  max-width: calc(50vw - 42px);
  padding: 8px;
  position: relative;
  text-align: center;
}

.ListPeopleMainEntry {
  font-size: 18px;
  max-width: 100%;
  padding: 8px 12px;
}

.ListPeopleEntryProfilePic {
  margin-bottom: 4px;
}

.ListPeopleEntryRelation {
  font-size: 12px;
  margin-top: 2px;
}

.ListPeopleAddPersonContainer {
  margin: 8px 0 12px;
}

.ListPeopleWrongPersonContainer {
  font-size: 12px;
}
