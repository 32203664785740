.PhotoGalleryContents {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}

.PhotoGalleryThumbnail {
  background-color: rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  max-height: 128px;
  margin: 0 8px 8px 0;
  max-width: 128px;
  object-fit: contain;
}
