.FullscreenFamilyTree {
  &-Popup {
    background-color: rgba(255, 255, 255, 0.98);
    height: 100%;
    left: 0;
    overflow: auto;
    overscroll-behavior: contain;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    &-FocusPerson {
      align-items: center;
      bottom: 0;
      display: flex;
      flex-direction: column;
      left: 50%;
      margin-bottom: 20px;
      position: fixed;
      transform: translateX(-50%);

      &-Title {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        margin-top: 8px;
        padding: 4px 8px;
      }
    }

    &-CloseButton {
      position: fixed;
      right: 16px;
      top: 16px;
    }
  }

  &-FocusBackground {
    background-image: radial-gradient(
      circle at center,
      rgb(204, 238, 188) 0%,
      transparent 50%
    );
    height: 300px;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 300px;
  }

  &-Person {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px;
    pointer-events: auto;
    position: absolute;
    transform: translate(-50%, -50%);

    &-Pic {
      border-radius: 36px;
    }

    &-Caption {
      position: absolute;
      text-align: center;
      top: 84px;

      &-Name {
        font-size: 0.9rem;
        text-align: center;
        width: 128px;
      }

      &-Relation {
        font-size: 0.7rem;
        margin-top: 4px;
        text-align: center;
      }
    }
  }
}
