html,
body {
  background-color: #f8f8f8;
  height: 100%;
}

body {
  display: flex;
  font-family: "Helvetica", sans-serif;
  justify-content: center;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: royalblue;
  cursor: pointer;
  text-decoration: underline;
}

button {
  background-color: rgba(225, 225, 225, 0.9);
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  color: royalblue;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 4px 8px;
}

button:disabled,
button[disabled] {
  color: rgba(0, 0, 0, 0.3);
}
