@import "../../../index.css";

@mixin innerContainer {
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  font-size: 1rem;
  margin: 20px 0;
  padding: 12px;
  text-align: center;
}

.ImageAdder {
  background-color: white;
  border-radius: 12px;
  margin: 20px;
  padding: 12px;

  &-Title {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
  }

  &-ImagePicker {
    @include innerContainer();

    &-FileInputContainer {
      margin: 24px 24px 12px;
    }
  }

  &-ImagePreview {
    @include innerContainer();

    &-Image {
      max-width: 100%;
    }

    &-Caption {
      font-size: 12px;
      margin-bottom: 12px;
      text-align: center;
    }

    &-ButtonContainer {
      margin: 12px 12px 0;

      &-CancelButton {
        margin-left: 12px;
      }
    }
  }

  &-ButtonContainer {
    text-align: center;
  }
}
