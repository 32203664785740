.ProfilePicContainer {
  position: relative;
}

.ProfilePic {
  border-radius: 8px;
  border: rgba(0, 0, 0, 0.05) 1px solid;
  box-sizing: border-box;
}

.ProfilePicWithNoImage {
  align-items: center;
  background-color: rgb(240, 240, 240);
  color: rgb(160, 160, 160);
  display: flex;
  justify-content: center;
}

.ProfilePicSlideshow {
  left: 0;
  position: absolute;
  top: 0;
}
