.SingleImageViewer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &-ImageWithOverlays {
    position: relative;
    user-select: none;

    &-Image {
      height: 100%;
      width: 100%;
    }

    &-Overlays {
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;

      &-FaceBounds {
        border: rgba(255, 255, 255, 0.3) 1px solid;
        box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 3px;
      }

      &-FaceLabel {
        background-color: rgba(0, 0, 0, 0.9);
        color: white;
        font-size: 12px;
        margin: 4px 0;
        padding: 4px;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
      }
    }
  }

  &-EditButtonContainer {
    top: 0;
    left: calc(50% - 12px);
    margin: 12px 16px;
    position: absolute;
    transform: translateX(-50%);
  }

  &-InfoFooter {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 8px;
    position: absolute;

    &-CaptionAndDate {
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      margin: 4px 0;
      padding: 8px;
      text-align: center;

      &-Date {
        color: yellow;
        padding: 0 8px;
      }
    }

    &-TaggedPeople {
      background-color: rgba(0, 0, 0, 0.8);
      margin: 4px 0;
      padding: 8px;
      text-align: center;

      &-Link {
        color: white;
        margin: 0 4px;
      }
    }
  }
}
