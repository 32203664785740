.ImageLayer {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
}
