.PersonWiki {
  font-size: 14px;
  width: 100%;

  p,
  h1,
  h2,
  h3,
  h4 {
    margin-left: 16px;
    margin-right: 16px;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 8px;
  }

  p {
    line-height: 20px;
    margin-block-start: 8px;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 22px;
    font-weight: 600;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  h4 {
    font-size: 18px;
  }

  &-InfoBoxContainer {
    border: solid 1px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 14px;
    margin: 12px;
    max-width: 400px;
    text-align: center;
    padding: 8px;
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    &-InfoBoxContainer {
      margin-bottom: 12px;
      margin-top: 12px;
      max-width: calc(100% - 24px);
      width: calc(100% - 24px);
    }
  }
}
