.InfoBox {
  &-ProfilePicContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    width: 100%;
  }

  &-ProfilePic {
    border-radius: 16px;
    position: relative;
  }

  &-DataField {
    padding: 4px;
  }
}
