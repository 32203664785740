@import "../../mixins";

.EditPerson {
  @include fullWidth;

  &-Editor {
    overflow: hidden;
    transform: height 0.5s;
  }
}

.EditPersonDataEditorContainer {
  display: flex;
  flex-direction: column;
  margin: 8px 16px;
  width: calc(100% - 16px * 2);
}

.EditPersonDataEditorLabel {
  font-size: 14px;
  margin-right: 8px;
}

.EditPersonDataEditorField {
  flex-grow: 1;
}

.EditPersonTextField {
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
}

.EditPersonArticleTextArea {
  box-sizing: border-box;
  font-size: 16px;
  margin: 16px;
  min-height: 400px;
  height: 100%;
  width: calc(100% - 2 * 16px);
}

.EditPersonProfileContainer {
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 4px;
  overflow: scroll;
}

.EditPersonSelectedProfile {
  box-sizing: border-box;
  border: rgba(0, 255, 0, 1) 2px solid;
}

.EditPersonButtonContainer {
  display: flex;
  justify-content: center;

  button {
    margin: 8px;
  }
}

.EditPersonPreviewContent {
  background-color: rgb(127, 255, 0);
  box-sizing: border-box;
  margin: 16px;
  max-height: calc(100% - 16px * 2);
  max-width: 800px;
  overflow: auto;
  width: calc(100% - 16px * 2);
}

.EditPersonPreviewPeekContainer {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px black dashed;
  margin: 8px;
  padding: 8px;
}

.EditPersonPreviewTitle {
  display: flex;
  justify-content: center;
  margin: 12px;
}

.EditPersonPreviewButtonContainer {
  display: flex;
  justify-content: center;
  margin: 18px;
}

.EditPersonPreviewButton {
  margin-right: 12px;
}
