.ErrorContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 24px;
  width: calc(100% - 24px * 2);
}

.ErrorContent {
  border: rgba(255, 0, 0, 0.4) 4px solid;
  border-radius: 16px;
  max-width: 500px;
  padding: 12px;
  width: 100%;
}
