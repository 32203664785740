.ImageViewer {
  height: 100%;
  position: relative;
  width: 100%;

  &-SwipeContainer {
    display: flex;
    height: 100%;
    left: -100vw;
    position: absolute;
    width: 300vw;

    &-Single {
      height: 100%;
      width: 100vw;
    }
  }

  &-SwipeButton {
    background-color: transparent;
    border: none;
    color: transparent;
    display: none;
    height: 100%;
    position: absolute;
    width: 40px;

    &Prev {
      left: 0;
    }

    &Next {
      right: 0;
    }

    &-Icon {
      height: 18px;
    }

    &-IconNext {
      transform: scaleX(-1);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &-SwipeButton {
      display: block;
    }

    &-SwipeButton:hover {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
    }
  }

  &-CloseButton {
    position: fixed;
    right: 8px;
    top: 8px;
    z-index: 10;
  }
}
