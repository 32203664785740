.CloseButton {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 18px;
  color: white;
  display: flex;
  height: 36px;
  justify-content: center;
  overflow: hidden;
  width: 36px;
}
