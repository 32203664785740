.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  width: 100%;

  &-Body {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    margin: 8px;
    overflow: hidden;
    z-index: 1;
  }

  &-Footer {
    align-items: center;
    display: flex;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    justify-content: center;
    margin-bottom: 12px;
    max-width: 1024px;
    padding: 0 12px;
    text-align: center;
    width: calc(100% - 24px);
  }
}
