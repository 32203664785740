.AnchorButton {
  background: none;
  border: none;
  color: royalblue;
  cursor: pointer;
  font: unset;
  font-size: unset;
  padding: 0;
  text-decoration: underline;
}
