.ImageEditor {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
  position: relative;
  width: 100%;

  &-ImageWithOverlays {
    position: relative;
    user-select: none;

    &-Image {
      max-height: 90vh;
      max-width: 100%;
      pointer-events: none;
    }

    &-Scrim {
      background-color: rgba(0, 0, 0, 0.1);
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &-NonEditBounds {
      border: rgba(255, 255, 255, 0.3) 1px solid;
      box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 3px;
    }

    &-EditLabel {
      background-color: rgba(0, 0, 0, 0.6);
      color: fuchsia;
      font-size: 12px;
      margin: 4px 0;
      pointer-events: none;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);
    }

    &-EditBounds {
      border: fuchsia 2px dashed;
    }
  }

  &-CaptionEditor {
    &-TextArea {
      box-sizing: border-box;
      flex-shrink: 0;
      font-size: 16px;
      height: 60px;
      margin: 8px;
      width: calc(100% - 16px);
    }
  }

  &-TaggedPeopleEditor {
    font-size: 14px;
    margin: 8px 12px 16px;
    text-align: center;

    &-Person {
      align-items: center;
      border: solid 1px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      margin: 4px;
      padding: 4px 8px;

      &-Face {
        margin: 4px 8px;

        &-Image {
          border-radius: 8px;
          position: relative;
        }

        &-EditImage {
          border: 3px dashed fuchsia;
        }
      }
    }
  }

  &-DateEditor {
    font-size: 14px;
    margin: 0 12px 16px;

    &-Input {
      margin-left: 8px;
    }
  }

  &-ButtonContainer {
    padding: 0 16px 16px;
    text-align: center;

    button {
      margin-right: 16px;
    }
  }
}

.FaceResizeHandle {
  align-items: center;
  background-color: fuchsia;
  border-radius: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 40px;
  justify-content: center;
  pointer-events: auto;
  position: absolute;
  width: 40px;

  &-Image {
    height: 32px;
    pointer-events: none;
    transform: rotate(90deg);
  }
}
